<template>
	<div>

		
		<div class="grid grid-cols-2 mb-4">
			<div class="col-span-1">
				<h2 class="text-2xl font-bold">Employee Loans</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for company’s name, status, etc."
						v-model="searchQuery">
					<ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
				</div>
			</div>
		</div>

		<div class="border border-solid border-blue-200">
			<div class="flex">
				<div class="duration-300" :class="{'w-full': !isOpen, 'w-1/2': isOpen}">
					<datatable
						:data="loans"
						:columns="columns"
						:query="searchQuery"
						:className="tableRowClassName"
						:loading="loading"
						:onClick="click"
						ref="table"
					/>
				</div>
				<div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
					<div class="animated animation-delay-300 fadeIn relative z-10">
						<div class="flex flex-row items-center justify-between mb-5">
							<h2 class="text-xl font-bold">{{ loan.user.name }}</h2>
							<div class="dropdown">
								<a href="#!" class="flex flex-col ml-3" dropdown>
									<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
									<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
									<div class="h-1 w-1 bg-gray-400 rounded-full"></div>
								</a>
								<ul class="dropdown-menu right whitespace-no-wrap">
									<!-- <a href="#!" class="dropdown-menu-item text-red-500" @click.prevent="deleteCompany(company)">Delete Company</a> -->
								</ul>
							</div>
						</div>
					</div>

					<div class="h-full overflow-y-auto">

						<div class="animated animation-delay-300 fadeIn">

							<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">

								<div class="col-span-1 order-1 md:order-1 lg:order-1 xl:order-1">
									<div
										class="px-8 xl:px-12 py-8 border-t border-b border-l border-r md:border-r-0 lg:border-r xl:border-r-0 border-blue-200 relative"
									>
										<span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Loan Request</div>
										</div>
										<div class="text-sm font-medium">₦{{ loan.loan_amount | currency }}</div>
									</div>
								</div>

								<div class="col-span-1 order-3 md:order-2 lg:order-3 xl:order-2">
									<div
										class="px-8 xl:px-12 py-8 md:border-t lg:border-t-0 xl:border-t border-b border-l border-r border-blue-200 relative"
									>
										<span class="absolute h-4 bg-orange-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Pay-Day Loan Limit</div>
										</div>
										<div class="text-sm font-medium">₦ {{ payDayLoanLimit | currency }}</div>
									</div>
								</div>

								<div class="col-span-1 order-2 md:order-3 lg:order-2 xl:order-3">
									<div
										class="px-8 xl:px-12 py-8 border-b border-l border-r md:border-r-0 lg:border-r xl:border-r-0 border-blue-200 relative"
									>
										<span class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Tenure (Months)</div>
										</div>
										<div class="text-sm font-medium">{{ loanTenure }}</div>
									</div>
								</div>

								<div class="col-span-1 order-4 md:order-4 lg:order-4 xl:order-4">
									<div
										class="px-8 xl:px-12 py-8 border-b border-l border-r border-blue-200 relative"
									>
										<span class="absolute h-4 bg-blue-800 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Standard Loan Limit</div>
										</div>
										<div class="text-sm font-medium">₦ {{ standardLoanLimit | currency }}</div>
									</div>
								</div>

							</div>


							<div class="grid grid-cols-1 xl:grid-cols-2 border-blue-200 border border-t-0 mb-8">

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-blue-200 relative">
										<span class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Salary</div>
										</div>
										<div class="text-xs font-medium">₦ {{ employee.profile ? employee.profile.salary : 0 | currency }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t xl:border-t-0 xl:border-l border-blue-200 relative">
										<span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Position</div>
										</div>
										<div class="text-xs font-medium">{{ employee.profile ? employee.profile.position : '---' }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t border-blue-200 relative">
										<span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Phone Number</div>
										</div>
										<div class="text-xs font-medium">{{ employee.profile ? employee.profile.phone_no : '---' }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative">
										<span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Email</div>
										</div>
										<div class="text-xs font-medium">{{ employee.email }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t border-blue-200 relative">
										<span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Employment Type</div>
										</div>
										<div class="text-xs font-medium">{{ employee.profile ? employee.profile.emp_type : '---' }}</div>
									</div>
								</div>

								<div class="col-span-1">
									<div class="px-8 xl:px-12 py-8 border-t xl:border-l border-blue-200 relative">
										<span class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-5" style="width: 3px"></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Loan Facility</div>
										</div>
										<div class="text-xs font-medium">{{ employee.profile ? employee.profile.current_loan_facility : '---' }}</div>
									</div>
								</div>

							</div>

							<h2 class="text-xl font-bold mb-5">Loan History</h2>

							<div class="border border-solid border-blue-200">
								<datatable :data="userLoanHistory" :columns="userLoanHistoryColumns" :loading="loading" />
							</div>

						</div>
						
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchQuery: '',
				company: null,
				loading: false,
				loans: [],
				loan: null,
				columns: [
					{
						name: 'user',
						th: 'Customer Name',
						render: (loan, user) => `${user.name} ${user.last_name}`
					},
					{
						name: 'loan_amount',
						th: 'Loan Amount',
					},
					{
						name: 'status',
						th: 'Status',
						render: (company, status) => {
							switch (status) {
								case 'approved':
									return `<div class="badge badge-green-soft-outline">
										Confirmed
									</div>`;
								case 'disbursed':
									return `<div class="badge badge-green-soft-outline">
										Disbursed
									</div>`;
								case 'declined':
									return `<div class="badge badge-red-soft-outline">
										Declined
									</div>`;
								case 'paid':
									return `<div class="badge badge-green-soft-outline">
										Paid
									</div>`;
								case 'pending':
								default:
									return `<div class="badge badge-orange-soft-outline">
										Pending
									</div>`;
							}
						}
					},
					{
						name: 'created_at',
						th: 'Request Date',
						render: (loan, created_at) => this.$options.filters.dateFormat(created_at, 'D, dd M Y h:ia')
					}
				],
				userLoanHistory: [],
				userLoanHistoryColumns: [
					{
						name: 'loan_amount',
						th: 'Loan Amount',
					},
					{
						name: 'status',
						th: 'Status',
						render: (company, status) => {
							switch (status) {
								case 'approved':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Confirmed
									</div>`;
								case 'declined':
									return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
								case 'paid':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Confirmed
									</div>`;
								case 'pending':
								default:
									return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
							}
						}
					},
					{
						name: 'created_at',
						th: 'Request Date',
						render: (loan, created_at) => this.$options.filters.dateFormat(created_at, 'D, dd M Y h:ia')
					}
				],
			}
		},
		computed: {
			employee() {
				return this.loan?.user;
			},
			isOpen() {
				return !!this.loan
			},
			loanTenure() {
				return this.loan?.tenure || 0;
			},
			payDayLoanLimit() {
				const salary = this.loan?.user?.profile?.salary;
				var limit = 0;
				if (salary) {
					limit = Number(salary) * .5;
				}

				return limit;
			},
			standardLoanLimit() {
				const salary = this.loan?.user?.profile?.salary;
				var limit = 0;
				if (salary) {
					limit = (Number(salary) * .5) * 12;
				}

				return limit;
			},
		},
		beforeMount() {
			this.getLoans();
		},
		methods: {
			click(loan) {
				this.loan = this.loan === loan ? null : loan;
				this.approved = false;
				this.declined = false;

				this.$refs.table.renderData();
				if (this.loan) {
					this.getUserLoans();
				}
			},
			async getLoans() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/companies/staff/loans/${this.user.company_id}`,
					headers: this.headers,
					success: response => {
						this.loans = response.data.loans;
					}
				});
				this.loading = false;
			},
			async getUserLoans() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/staff/loans/${this.loan.user.id}?exclude=${this.loan.id}`,
					headers: this.headers,
					success: response => {
						this.userLoanHistory = response.data.loans;
					}
				});
				this.loading = false;
			},
			tableRowClassName(row) {
				return row.id === this.loan?.id ? 'selected' : '';
			},
		}
	}
</script>